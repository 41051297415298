<template>
  <SpeciesPage />
</template>

<script>
  import SpeciesPage from '@/components/species/Species'
  export default {
    name: 'Species',

    components: {
      SpeciesPage,
      
    },
  }
</script>
 