<template>
  <ProjectsDetailsPage />
</template>

<script>
  import ProjectsDetailsPage from '@/components/projects/ProjectsDetails'
  export default {
    name: 'ProjectsDetails',

    components: {
      ProjectsDetailsPage,
      
    },
  }
</script>
