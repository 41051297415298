
<template>
  <v-container>
    <v-card>
      <v-card-title class="text-center">
        {{ datas.name }}
      </v-card-title>
      <v-expand-transition>
        <v-row justify="space-around">
          <v-col cols="5">
            <v-row> Diameter estimation by age </v-row>
            <v-row>
              <v-col cols="2"> Class : </v-col>
              <v-col cols="5">
                <v-select
                  :items="diameterChartData.select.classes"
                  v-model="diameterChartData.select.selected"
                  @change="diameterSelectChange"
                  solo
                ></v-select>
              </v-col>
            </v-row>
            <canvas id="diameter-chart"></canvas>
          </v-col>
          <v-col cols="5">
            <v-row> Height estimation by age </v-row>
            <v-row>
              <v-col cols="2"> Class : </v-col>
              <v-col cols="5">
                <v-select
                  :items="heightChartData.select.classes"
                  v-model="heightChartData.select.selected"
                  @change="heightSelectChange"
                  solo
                ></v-select>
              </v-col>
            </v-row>
            <canvas id="height-chart"></canvas>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card>
  </v-container>
</template>

<script>
import { Chart, registerables } from "chart.js";
import axios from "axios";

export default {
  data() {
    return {
      diameterChart: null,
      heightChart: null,
      diameterChartData: {
        select: {
          classes: [],
          selected: "",
        },
        type: "line",
        data: {
          labels: [],
          datasets: [
            {
              label: "Fertility class (static)",
              data: [],
              backgroundColor: "rgba(54,73,93,.5)",
              borderColor: "#36495d",
              borderWidth: 3,
            },
            {
              label: "Calculated (Gompertz)",
              data: [],
              backgroundColor: "rgba(71, 183,132,.5)",
              borderColor: "#47b784",
              borderWidth: 3,
            },
          ],
        },
        options: {
          responsive: true,
          lineTension: 1,
        },
      },
      heightChartData: {
        select: {
          classes: [],
          selected: "",
        },
        type: "line",
        data: {
          labels: [],
          datasets: [
            {
              label: "Fertility class (static)",
              data: [],
              backgroundColor: "rgba(54,73,93,.5)",
              borderColor: "#36495d",
              borderWidth: 3,
            },
          ],
        },
        options: {
          responsive: true,
          lineTension: 1,
        },
      },
    };
  },
  created() {
    Chart.register(...registerables);
  },
  props: {
    datas: Object,
  },
  methods: {
    diameterSelectChange() {
      var itemIndex = 0;
      var vm = this;
      this.datas.diameterYieldTable.forEach(function (element, index) {
        if (element.class == vm.diameterChartData.select.selected) {
          itemIndex = index;
        }
      });
      vm.diameterChartData.data.datasets[0].data = vm.datas.diameterYieldTable[itemIndex].age;
      axios
        .get(vm.$root.API_URL + "/species/diameters", {
          params: {
            species: vm.datas.name,
            className: vm.diameterChartData.select.selected,
            nbYear: vm.datas.diameterYieldTable[itemIndex].age.length,
          },
        })
        .then((response) => {
          vm.diameterChartData.data.datasets[1].data =
            response.data.results.diameters;
          vm.diameterChart.update();
        });
    },
    heightSelectChange() {
      var itemIndex = 0;
      var vm = this;
      this.datas.heightYieldTable.forEach(function (element, index) {
        if (element.class == vm.heightChartData.select.selected) {
          itemIndex = index;
        }
      });
      vm.heightChartData.data.datasets[0].data =  vm.datas.heightYieldTable[itemIndex].age;
      vm.heightChart.update();
    },
  },
  mounted() {
    prepareChartData(this);
  },
};

function prepareChartData(vm) {
  initSelects(vm);
  var count = vm.datas.diameterYieldTable[0].age.length;
  var index = 1;
  vm.diameterChartData.data.labels = [];
  while (count--) {
    vm.diameterChartData.data.labels.push(index++);
  }
  vm.heightChartData.data.labels = [];
  count = vm.datas.heightYieldTable[0].age.length;
  index = 1;
  while (count--) {
    vm.heightChartData.data.labels.push(index++);
  }

  vm.diameterChartData.data.datasets[0].data =
    vm.datas.diameterYieldTable[0].age;
  axios
    .get(vm.$root.API_URL + "/species/diameters", {
      params: {
        species: vm.datas.name,
        className: vm.diameterChartData.select.selected,
        nbYear: index,
      },
    })
    .then((response) => {
      vm.diameterChartData.data.datasets[1].data =
        response.data.results.diameters;
      const ctx = document.getElementById("diameter-chart");
      vm.diameterChart = new Chart(ctx, vm.diameterChartData);
    });

  vm.heightChartData.data.datasets[0].data = vm.datas.heightYieldTable[0].age;
  const ctx1 = document.getElementById("height-chart");
  vm.heightChart = new Chart(ctx1, vm.heightChartData);
}

function initSelects(vm) {
  var classes = [];
  if (vm.datas.gompertzDiameterAdjust) {
    vm.datas.gompertzDiameterAdjust.forEach((element) => {
      classes.push(element.name);
    });
  } else if (vm.datas.diameterYieldTable) {
    vm.datas.diameterYieldTable.forEach((element) => {
      classes.push(element.name);
    });
  }

  vm.diameterChartData.select.classes = classes;
  vm.diameterChartData.select.selected = classes[0];
  vm.heightChartData.select.classes = classes;
  vm.heightChartData.select.selected = classes[0];
}
</script>
