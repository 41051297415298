
<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="10"
    class="elevation-2"
    :search="search"
    :custom-filter="filterText"

  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          label="Search species name"
          class="mx-4"
        ></v-text-field>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              New Species
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">New Species</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-text-field
                    v-model="editedItem.name"
                    label="Name"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-text-field
                    v-model="editedItem.scientificName"
                    label="Scientific name"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-checkbox
                    v-model="editedItem.tropicale"
                    label="Tropicale"
                  ></v-checkbox>
                </v-row>
                <v-row>
                 <v-textarea
                    v-model="editedItem.description"
                    label="Description"
                  ></v-textarea>
                </v-row>

              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      <v-icon small @click="copy(item)"> mdi-content-copy </v-icon>
    </template>
    <template v-slot:item.detail="{ item }">
      <v-icon small @click="editItem(item)"> mdi-chevron-double-right </v-icon>
    
    </template>

  </v-data-table>

</template>

<script>
import axios from "axios";


export default {
  name: "Species",
  data() {
    return {
   
      search: "",
      dialogDelete: false,
      dialog: false,
      headers: [
        {
          text: "Id",
          value: "id",
          align: "left",
        },
        {
          text: "Name",
          value: "name",
          align: "left",
          sortable: true,
        },
        {
          text: "Scientific name",
          value: "scientificName",
          align: "left",
          sortable: true,
        },
        {
          text: "Tropicale",
          value: "tropicale",
          align: "left",
          sortable: true,
        },
        {
          text: "Description",
          value: "description",
          align: "left",
          sortable: true,
        },
        { text: "Actions", value: "actions", sortable: false },
        { text: "", value: "detail", sortable: false },
      ],
      items: [],
      editedIndex: -1,
      editedItem: {
        name: "",
        scientificName:"",
        tropicale:false,
        description:""
      },
    };
  },
  props: {
    msg: String,
  },
  components: {},
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    // eslint-disable-next-line no-unused-vars
    filterText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
      );
    },
    handleClick(item) {
      this.$router.push("speciesdetails/:" + item._id);
    },
    editItem(item) {
      this.$router.push("speciesdetails/:" + item._id);
    },
    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      var item = this.items[this.editedIndex];
      var context = this;
      if(item && item._id) {
      axios
        .delete(this.$root.API_URL + "/species", {
          params: { _id: item._id },
        })
        .then((response) => {
          context.closeDelete();
          context.load();
        })
        .catch((error) => {
          context.closeDelete();
        });

      }else{
          this.closeDelete();
        
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    save() {
      var context = this;
      axios
      .post(this.$root.API_URL + "/species", this.editedItem)
      .then((response) => {
          context.close();
          context.load();

    })
      .catch((error) => {
        console.log(error);
          context.close();
      });

    },
    load(){
    axios
      .get(this.$root.API_URL + "/species")
      .then((response) => {
        this.items = response.data.results;
      })
      .catch((error) => {
        console.log(error);
      });

    },
    copy(item){
      var itemToClipBoard = { 
        id: item.id, 
        ownerId : item.ownerId, 
        name : item.name, 
        scientificName : item.scientificName, 
        tropical : item.tropical,
        description : item.description
        }
      navigator.clipboard.writeText(JSON.stringify(itemToClipBoard));
      itemToClipBoard.scientificName + " copied to clipboard";
    }
  },
  mounted() {
    this.load();


  },

  //
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->



<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: white;
}
</style>
