import Vue from 'vue'
import VueRouter from 'vue-router'
import Projects from '../views/Projects.vue'
import ProjectsDetails from '../views/ProjectsDetails.vue'
import Params from '../views/Params.vue'
import Geoclimat from '../views/Geoclimat.vue'
import Infradensity from '../views/Infradensity.vue'
import Species from '../views/species/Species.vue'
import SpeciesDetails from '../views/species/SpeciesDetails.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'projects',
    component: Projects
  },
  {
    path: '/projectsdetails/:_id',
    name: 'ProjectsDetails',
    component: ProjectsDetails 
  },

  {
    path: '/geoclimat',
    name: 'Geoclimat',
    component: Geoclimat
  },
  {
    path: '/species',
    name: 'Species',
    component: Species
  },
  {
    path: '/speciesdetails/:_id',
    name: 'SpeciesDetails',
    component: SpeciesDetails
  },
  {
    path: '/infradensity',
    name: 'Infradensity',
    component: Infradensity
  },
  
  {
    path: '/params',
    name: 'Params',
    component: Params
  }
]

const router = new VueRouter({
  routes
})

export default router
