<template>
  <v-container>
    <v-card :color="color">
      <v-card-subtitle class="text-center">
        {{ title }}
        <v-btn icon @click="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
        <v-btn>
          <v-file-input
            style="width: 20px"
            accept="text/csv"
            v-model="chosenFile"
            @change="upload()"
            class="text-center caption"
          />
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" dark v-bind="attrs" v-on="on">
              mdi-help
            </v-icon>
          </template>
          <span
            >Yield table csv import<br />
            <br />16 years/5 Classes, yield table csv file sample : <br /><br />
            class ,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16<br />
            X
            ,1.08,2.15,3.23,4.30,5.38,6.46,7.53,8.61,9.68,10.78,12.77,14.59,16.26,17.81,19.26,20.60<br />
            4
            ,1.43,2.86,4.30,5.73,7.16,8.59,10.03,11.46,12.89,14.19,16.43,18.47,20.34,22.08,23.69,25.20<br />
            5
            ,1.31,2.61,3.92,5.22,6.53,7.83,9.14,10.44,11.75,13.04,15.14,17.06,18.82,20.45,21.97,23.40<br />
            6
            ,1.11,2.23,3.34,4.46,5.57,6.68,7.80,8.91,10.03,11.15,13.13,14.94,16.60,18.15,19.58,20.92<br />
            7
            ,0.92,1.85,2.77,3.69,4.62,5.54,6.46,7.38,8.31,9.38,11.24,12.94,14.50,15.95,17.30,18.56<br />
            8
            ,0.60,1.21,1.81,2.42,3.02,3.63,4.23,4.84,5.44,6.12,7.91,9.54,11.04,12.43,13.73,14.94<br />
          </span>
        </v-tooltip>
      </v-card-subtitle>
      <v-expand-transition>
        <div v-show="show && datas && datas.length">
          <v-divider></v-divider>
          <v-container>
            <v-row>
              <v-col class="text-center caption"> Yield class </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center caption"> Age </v-col>
              <v-col
                class="text-center caption"
                v-for="(item, index) in datas"
                :key="index"
                :item="item"
                >{{ item.class }}
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
          <v-container
            style="max-height: 400px"
            class="overflow-y-auto"
            v-if="datas && datas.length"
          >
            <v-row v-for="index in datas[0].age.length" :key="index">
              <v-col class="text-center caption"> {{ index }} </v-col>

              <v-col
                v-for="(item1, index1) in datas"
                :key="index1"
                :item="item1"
                class="text-center caption"
              >
                <vue-numeric-input
                  width="Auto"
                  align="center"
                  v-model="datas[index1].age[index - 1]"
                  :controls="false"
                  @input="inputChange()"
                  :readonly="true"
                />
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-expand-transition>
    </v-card>
  </v-container>
</template>

<script>
import csv from "csv-parser";
export default {
  data() {
    return {
      show: false,
      chosenFile: null
    };
  },
  props: {
    title: String,
    datas: Array,
    color: String,
    subcolor: String,
  },
  methods: {
    csvJSON(csv) {
      var lines = csv.split("\n");
      var result = [];
      var header = lines[0].split(",");
      if(header[0] != "class") {
        return null;
      }
      lines.map(function (line, indexLine) {
        if (indexLine < 1) return; // Jump header line
        var obj = { class:"", age : []};
        line.replace("/r", "");
        var currentline = line.split(",");
        header.map(function (header, indexHeader) {
          if(indexHeader == 0) {
            obj.class = currentline[indexHeader];
          }else{
            obj.age.push(parseFloat(currentline[indexHeader]))
          }
        });

        result.push(obj);
      });

      result.pop(); // remove the last item because undefined values

      return result; 
    },
    upload() {
      var vm = this;
      var reader = new FileReader();
      reader.readAsText(this.chosenFile);
      reader.onload = () => {
        var csv = reader.result;
        var parseCsv = vm.csvJSON(csv);
        if(!parseCsv) {
        this.$alert("Invalid Yield table csv import file ");
        }else{
          vm.$emit("clicked", parseCsv);
          vm.show = true;
          vm.chosenFile = null;

        }
      };
    },
    inputChange() {
      this.$emit("clicked", this.datas);
    },
  },
  mounted() {},
};
</script>
<style scoped>
.vue-numeric-input .numeric-input {
  border: 1px solid rgb(0, 0, 0, 0.8) !important;
  text-align: center;
  min-width: 180px;
}
.v-text-field__slot {
  display: none;
}
</style>