<template>
  <v-card>
    <v-tabs color="deep-purple accent-4" right>
      <v-tab>Caracterictics</v-tab>
      <v-tab>Estimation</v-tab>
      <v-tab-item v-for="n in 2" :key="n">
        <v-container fluid>
          <ProjectDatas v-bind:datas=datas v-if="n==1"> </ProjectDatas>
          <ProjectChart   v-if="n==2"> </ProjectChart>
      
        </v-container>
        
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import ProjectDatas from "@/components/projects/ProjectDatas.vue";
import ProjectChart from "@/components/projects/ProjectChart.vue";
import axios from "axios";
export default {
  name: "ProjectsDetails",
  data() {
    return {
      datas :{}
    };
  },
  components: {
    ProjectDatas,
    ProjectChart
  },

  methods: {
  },
  mounted() {
    var vm = this;
    var id = this.$route.params._id.replace(":", "");

    axios
      .get(this.$root.API_URL+"/projects", {params: { _id: id} })
      .then((response) => {
        vm.datas = response.data.results[0];
      })
      .catch((error) => {
        console.log(error);
      });
  },

  //
};
</script>




<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: white;
}
</style>
