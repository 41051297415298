<template>
  <ProjectsPage />
</template>

<script>
  import ProjectsPage from '../components/projects/Projects'
  export default {
    name: 'Projects',

    components: {
      ProjectsPage,
      
    },
  }
</script>
