<template>
  <GeoclimatPage />
</template>

<script>
  import GeoclimatPage from '../components/Geoclimat'
  export default {
    name: 'Geoclimat',

    components: {
      GeoclimatPage,
      
    },
  }
</script>
