

<template>
  <v-container>
    <v-row>
      <v-col v-for="(card, icard) in vcards" :key="icard" :item="card">
        <v-card :width="card.width" :color="card.color">
          <v-card-subtitle class="text-center">
            {{ card.title }}
            <v-btn
              icon
              @click="
                collapseAll();
                card.show = !card.show;
              "
            >
              <v-icon>{{
                card.show ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
            <br />
            {{ card.subtitle }}
          </v-card-subtitle>
          <v-expand-transition>
            <div v-show="card.show">
              <v-divider></v-divider>
              <v-container>
                <v-row>
                  <v-col
                    v-for="(head, col) in card.headers"
                    :key="col"
                    :item="head"
                    class="text-center caption"
                  >
                    {{ head }}
                  </v-col>
                </v-row>
              </v-container>
              <v-divider></v-divider>
              <v-container>
                <v-row
                  no-gutters
                  v-for="(line, col) in card.lines"
                  :key="col"
                  :item="line"
                >
                  <v-col v-for="(item, lcol) in line" :key="lcol" :item="item">
                    <p v-if="!item.model" class="text-center caption">
                      {{ item }}
                    </p>

                    <vue-numeric-input
                      v-if="item.model"
                      class="caption"
                      width="auto"
                      align="center"
                      v-model="item.value"
                      :min="0.0"
                      @input="inputChange()"
                      :controls="false"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
    <v-footer v-if="toUpdate" height="80px" padless outset>
      <v-row align="center" justify="space-around">
        <v-btn depressed @click="cancel"> Cancel</v-btn>
        <v-btn depressed color="primary" @click="save">Save </v-btn>
      </v-row>
    </v-footer>
  </v-container>
</template>
<script>
const SOCREF = 0;
const COMBUSTION_FACTOR = 1;
const EMISSION_FACTOR = 2;
const CARBON_STOCK = 3;
const STOCKCHANGEFACTOR_CROPLAND = 4;
//const STOCKCHANGEFACTOR_GRASSLAND = 5;

import axios from "axios";
export default {
  data() {
    return {
      test: 55,
      parameters: { name: "toto" },
      val: 0,
      toUpdate: false,
      vcards: [
        {
          title:
            "SOCREF for mineral soils (t C ha-1 in the 30 first centimeters)",
          width: "500px",
          color: "rgb(255,217,102)",
          headers: [
            "Climat",
            "HAC soil",
            "LAC soil",
            "Sandy soil",
            "Volcanic soil",
          ],
          lines: [],
          show: true,
        },
        {
          title: "Combustion factor",
          width: "500px",
          color: "rgb(255,217,102)",
          headers: ["Forest type", "Mean  age", "Default value"],
          lines: [],
          show: true,
        },
        {
          title: "Emission factor",
          width: "500px",
          color: "rgb(255,242,204)",
          headers: ["Forest type", "CH4", "N2O"],
          lines: [],
          show: true,
        },
        {
          title: "Carbon stock in dead wood and litter",
          width: "500px",
          color: "rgb(221,235,247)",
          headers: [
            "Forest type",
            "Elevation (m)",
            "Precipitation (mm/yr)",
            "DFdw (%)",
            "DFli (%)",
          ],
          lines: [],
          show: true,
        },
        {
          title:
            "Relative stock change factors for different management activities ",
          subtitle: "Baseline : Cropland",
          width: "600px",
          color: "rgb(165,165,165)",
          headers: ["Type", "Niveau", "Dry", "Moist", "Wet", "Montane"],
          lines: [],
          show: true,
        },

        {
          title:
            "Relative stock change factors for different management activities ",
          subtitle: "Baseline : Grassland",
          width: "600px",
          color: "rgb(165,165,165)",
          headers: ["Type", "Niveau", "Dry", "Moist", "Wet", "Montane"],
          lines: [],
          show: true,
        },
      ],
    };
  },
  mounted() {
    var datas = this;
    axios
      .get(this.$root.API_URL + "/parameters")
      .then((response) => {
        this.parameters = response.data.results;
        setData(this.vcards, this.parameters);

      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    reload() {
      location.reload();
    },
    inputChange() {
      this.toUpdate = true;
    },
    cancel() {
      this.toUpdate = false;
      this.reload();
    },
    save() {
      var datas = this;
      datas.toUpdate = false;
      getData(this.vcards, this.parameters);
      
      axios
        .post(this.$root.API_URL + "/parameters", {
          datas: this.parameters,
        })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
      
    },
    collapseAll() {
      this.vcards.forEach(function (card) {
        //      card.show = false;
      });
    },
  },
};

function setData(vcards, parameters) {
  vcards.forEach((card) => {
    card.lines = [];
  });
  parameters.combustionFactor.forEach((value) => {
    value.age.forEach((subvalue) => {
      vcards[COMBUSTION_FACTOR].lines.push([
        value.forestType,
        subvalue.name,
        { model: true, value: subvalue.value },
      ]);
    });
  });
  
  parameters.SOCref.forEach((climat) => {
    vcards[SOCREF].lines.push([
      climat.climat,
      { model: true, value:climat.hac },
      { model: true, value: climat.lac },
      { model: true, value: climat.sandy },
      { model: true, value: climat.volcanic },
    ]);
  });

  parameters.emissionFactor.forEach((value) => {
    vcards[EMISSION_FACTOR].lines.push([
      value.forestType,
      { model: true, value: value.CH4 },
      { model: true, value: value.N2O },
    ]);
  });

  parameters.carbonStockDefaultFactor.forEach((climat) => {
    climat.altitude.forEach((alt) => {
      alt.precipitation.forEach((precip) => {
        vcards[CARBON_STOCK].lines.push([
          climat.forestType,
          alt.description,
          precip.description,
          { model: true, value: precip.deadWoodDF },
          { model: true, value: precip.litterDF },
        ]);
      });
    });
  });

  parameters.stockChangeFactor.forEach((activity, index) => {
    activity.types.forEach((type) => {
      type.levels.forEach((level) => {
        vcards[STOCKCHANGEFACTOR_CROPLAND + index].lines.push([
          type.name,
          level.name,
          { model: true, value: level.dry },
          { model: true, value: level.moist },
          { model: true, value: level.wet },
          { model: true, value: level.montane },
        ]);
      });
    });
  });
  
}

function getData(vcards, parameters) {
  var index = 0;
  parameters.combustionFactor.forEach((value) => {
    value.age.forEach((subvalue) => {
      subvalue.value = vcards[COMBUSTION_FACTOR].lines[index++][2].value;
    });
  });

  index = 0;
  parameters.SOCref.forEach((climat) => {
    var datas = vcards[SOCREF].lines[index++];
    climat.hac  = datas[1].value;
    climat.lac  = datas[2].value;
    climat.sandy = datas[3].value;
    climat.volcanic = datas[4].value;
  });

  index = 0;
  parameters.emissionFactor.forEach((factor) => {
    var datas = vcards[EMISSION_FACTOR].lines[index++];
    factor.CH4 = datas[1].value;
    factor.N2O = datas[2].value;
  });


  index = 0;
  parameters.carbonStockDefaultFactor.forEach((climat) => {
    climat.altitude.forEach((alt) => {
      alt.precipitation.forEach((precip) => {
      var datas = vcards[CARBON_STOCK].lines[index++];
        precip.deadWoodDF = datas[3].value;
        precip.litterDF =  datas[4].value
      });
    });
  });

  parameters.stockChangeFactor.forEach((activity, cardIndex) => {
  index = 0;
    activity.types.forEach((type) => {
      type.levels.forEach((level) => {
        var datas = vcards[STOCKCHANGEFACTOR_CROPLAND + cardIndex].lines[index++];
        level.dry = datas[2].value;
        level.moist  = datas[3].value;
        level.wet  = datas[4].value;
        level.montane = datas[5].value;
      });
    });
  });
}
</script>
<style scoped>
.centered-input input {
  text-align: center;
}
.vue-numeric-input .numeric-input {
  border: 1px solid rgb(0, 0, 0, 0.12) !important;
  text-align: center;
}
.input {
  text-align: center !important;
}
p {
  margin: 5px 0px;
}
.v-footer {
  background-color: white !important;
}

</style>