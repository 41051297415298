<template>
  <SpeciesDetailsPage />
</template>

<script>
  import SpeciesDetailsPage from '@/components/species/SpeciesDetails'
  export default {
    name: 'SpeciesDetails',

    components: {
      SpeciesDetailsPage,
      
    },
  }
</script>
