<template>
<v-container>
      <v-row v-if="toUpdate" align="center" justify="space-around" margin="10">
        <v-btn depressed @click="cancel"> Cancel</v-btn>
        <v-btn depressed color="primary" @click="save">Save </v-btn>
      </v-row>

<v-col >
<v-card >
          <v-card-title class="text-center">
            {{datas.name}}
          </v-card-title>
<!--          
          <v-row>
            <v-col cols="6">
              <gompertz 
                title="Gompertz adjustment for diameter" 
                v-bind:datas="datas.gompertzDiameterAdjust" 
                color ="rgb(244,176,132)"
                subcolor ="rgb(252,228,214)"
                @clicked="gompertzDiameterAdjustClicked"
                >
                </gompertz> 
            </v-col>
            <v-col cols="6">
              <gompertz 
                title="Gompertz adjustment for heigh" 
                v-bind:datas="datas.gompertzHeightAdjust" 
                color ="rgb(244,176,132)"
                subcolor ="rgb(252,228,214)"
                @clicked="gompertzHeightAdjustClicked"
                >
                </gompertz> 
            </v-col>
          </v-row>
          -->
          <v-row>
            <v-col cols="12">
              <yieldTable 
                title="Diameter Yield Classes" 
                v-bind:datas="datas.diameterYieldTable" 
                color ="rgb(226,239,218)"
                @clicked="diameterYieldTableClicked"
                >
                </yieldTable> 

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <yieldTable 
                title="Height Yield Classes" 
                v-bind:datas="datas.heightYieldTable" 
                color ="rgb(226,239,218)"
                @clicked="heightYieldTableClicked"
                >
                </yieldTable> 

            </v-col>
          </v-row>
</v-card>
</v-col> 
</v-container>

</template>

<script>
import axios from "axios";
//import gompertz from "@/components/species/gompertz.vue";
import yieldTable from "@/components/species/yieldtable.vue";

export default {
  name: "SpeciesDatas",
  data() {
    return {
      toUpdate:false,
    };
  },
    props: {
    datas:Object
  },
  components: {
  //  gompertz, 
    yieldTable
  },

  methods: {
    gompertzHeightAdjustClicked(value){
      this.datas.gompertzHeightAdjust = value;
      this.toUpdate = true;
    },
    gompertzDiameterAdjustClicked(value){
      this.datas.gompertzDiameterAdjust = value;
      this.toUpdate = true;
    },
    heightYieldTableClicked(value){
      this.datas.heightYieldTable = value;
      this.toUpdate = true;
    },
    diameterYieldTableClicked(value){
      this.datas.diameterYieldTable = value;
      this.toUpdate = true;
    },
       cancel() {
      this.toUpdate = false;
      location.reload();
    },
    save() {
      this.toUpdate = false;
      var id = this.datas._id;
      axios
        .put(this.$root.API_URL + "/species/"+this.datas._id, {
          datas: this.datas,
        })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
      
    },

  },
  mounted() {

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->



<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: white;
}
</style>
