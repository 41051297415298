
<template>

  <v-app>
<v-app-bar app light>
   
  <h1>Rfa carbon simulator</h1>
    <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tab to="/">simulator</v-tab>
          <v-tab to="/species">Species</v-tab>
          <v-tab to="/params">Parameters</v-tab>
          <v-tab to="/infradensity">Infradensity</v-tab>
<!--          <v-tab to="/geoclimat">Geo Climat</v-tab>-->
        </v-tabs>
      </template>
  <!--
  <v-avatar :tile="true">
    <img :src="require('@/assets/logo.png')" alt="logo">
  </v-avatar>
  <v-toolbar-title>Header text</v-toolbar-title>
  -->
</v-app-bar>
     <v-main>
        <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    logo: require('./assets/logo-head.png')
            
    //
  }),
computed: {
    currentRouteName() {
        return this.$route.name;
    }
}
};
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: white;
}
.v-main {
  padding:120px 0 0!important;
}

</style>
<style>
@font-face{
    font-family: "myfont"; 
    src: url(./assets/fonts/SegoeUI.woff) format("woff");
    }
 
html *
{
    font-size:18px;
    color:#000;
     font-family:myfont;
}
*{
     font-family:myfont;
}
.v-btn {
    color:White!important;
    background: linear-gradient(97.21deg,#639f42,#2a7439);
}
</style>