<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="5"
    class="elevation-2"
    :search="search"
    :custom-filter="filterText"
  >
       <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search project name"
          class="mx-4"
        ></v-text-field>
      </template>
      </v-data-table>
</template>

<script>
import axios from 'axios'
export default {
  name: "Geoclimat",
   data () {
    return ( {
   search: '',
   headers: [
      {
        text: "Family",
        value: 'family',
        align: 'left',
        sortable: true
      },
      {  text: "Name", value: 'name'  },
      {  text: "Infradensity", value: 'infradensity'  },
      {  text: "Region", value: 'region'  }
    ],
    items: [
          
    ]
})
   },
  props: {
    msg: String
  },
    components: {
  },
  
    methods: {
       // eslint-disable-next-line no-unused-vars
      filterText (value, search, item) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
      },
    },
  mounted(){
    axios
    .get(this.$root.API_URL + '/infradensity')
    .then(response => {
      this.items = response.data.results
    })
    .catch(error => {
      console.log(error)
    })
  }

  //
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->



<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: white;
}
</style>
