<template>
<v-container>
  <v-card>
        <router-link to="/species">
        <v-icon > mdi-chevron-double-left </v-icon>
        </router-link>
    <v-tabs color="deep-purple accent-4" right>
      <v-tab>Details</v-tab>
      <v-tab>Classes</v-tab>
      <v-tab>Graphs</v-tab>
      <v-tab-item v-for="n in 3" :key="n">
        <v-container fluid>
          <SpeciesDatas v-bind:datas=datas v-if="n==2"> </SpeciesDatas>
          <SpeciesChart  v-bind:datas=datas v-if="n==3"> </SpeciesChart>
               <v-row>
      <v-col cols="6"  v-if="n==1">
        <v-card>
          <v-card-title class="text-center"> {{datas.name}} </v-card-title>
          <v-card-text>
            <pre>id : {{ datas.id }}</pre>
            <pre>Name : {{ datas.name }}  </pre>
            <pre>Scientific name : {{ datas.scientificName }}  </pre>
            <v-checkbox
                  v-model="datas.tropical"
                  label="Tropical"
                  color="darken-3"
                ></v-checkbox>
            <pre style="display:flex" >Description :  
              <!--
                 <vue-input
                      class="caption"
                      align="center"
                      v-model="datas.description"
                      :min="0.0"
                      @input="inputChange()"
                      :controls="false"
                    />
-->                    
                    </pre>
                  
          </v-card-text>
        </v-card>
      </v-col>
           </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs>
  </v-card>
 <div class="text-center ma-2">
    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
  absolute
      centered   >
toto
    </v-snackbar>
  </div>
</v-container>
</template>

<script>
import axios from "axios";
import SpeciesDatas from "@/components/species/SpeciesDatas.vue";
import SpeciesChart from "@/components/species/SpeciesChart.vue";

export default {
  name: "SpeciesDetails",
  data() { 
    return {
      snackbar: true,
      text: `Hello, I'm a snackbar`,
      datas: {}

    };
  },
  components: {
    SpeciesChart,
    SpeciesDatas,
  },
  mounted() {
    var vm = this;
    var id = this.$route.params._id.replace(":", "");
    //    var id = this.$route.params._id.replace(":","");
    axios
      .get(this.$root.API_URL + "/species", { params: { _id: id} })
      .then((response) => {
        vm.datas = response.data.results[0];
        
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style scoped>
  router-link{
    padding:20px
  }
  a {
    text-decoration: none;
  }
</style>
