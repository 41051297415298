<template>
  <ParamsPage />
</template>

<script>
  import ParamsPage from '../components/Params'

  export default {
    name: 'Params',

    components: { 
      ParamsPage,
    },
  }
</script>
