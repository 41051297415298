<template>
  <InfradensityPage />
</template>

<script>
  import InfradensityPage from '../components/Infradensity'
  export default {
    name: 'Infradensity',

    components: {
      InfradensityPage,
      
    },
  }
</script>
