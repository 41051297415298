<template>
  <v-container>
    <v-row v-if="toUpdate" align="center" justify="space-around" margin="10">
      <v-btn depressed @click="cancel"> Cancel</v-btn>
      <v-btn depressed color="primary" @click="save">Save </v-btn>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title class="text-center"> Biome </v-card-title>
          <v-card-text>
            Name : {{ datas.name }}  
            Country : {{ datas.country }} 
            <pre>idBiome : {{ datas.id }}</pre>
            <pre>Activity : {{ datas.activity }}</pre>
            <pre>Latitude : {{ datas.lat }}</pre>
            <pre>Longitude : {{ datas.long }}</pre> 
            <pre>Area : {{ datas.area }}</pre>
            <pre>Duration : {{ datas.duration }}</pre>
          </v-card-text>
        </v-card>
      </v-col>
        <v-col cols="4">
        <v-card v-if="datas.options">
          <v-card-title class="text-center">
            Optional compartments
          </v-card-title>
          <v-container fluid>
            <v-row>
              <v-col cols="4" sm="4" md="4">
                <v-checkbox
                  v-model="datas.options.shrubs"
                  label="Shrubs"
                  color="red"
                  value="red"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-checkbox
                  v-model="datas.options.deadWood"
                  label="Dead Wood"
                  color="red"
                  value="red"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-checkbox
                  v-model="datas.options.litter"
                  label="Litter"
                  color="red darken-3"
                  value="red darken-3"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="datas.options.shrubs">
                <pre>Crown cover of shrubs (%)</pre>
                <vue-numeric-input
                  v-if="datas.options.shrubs"
                  class="caption"
                  width="auto"
                  align="center"
                  :min="0.0"
                  v-model="datas.options.shrubsCrownCover"
                  @input="inputChange()"
                  :controls="false"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-card>
          <v-card-title class="text-center"> Species </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-left"
                    v-for="item in ['Name', 'Density', 'surface', 'Discount']"
                    :key="item"
                  >
                    {{ item }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in datas.species" :key="item.name">
                  <td>{{ item.name }}</td>
                  <td>{{ item.density }}</td>
                  <td>{{ item.area }}</td>
                  <td>{{ item.discount }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "ProjectDatas",
  data() {
    return {
      toUpdate: false,
    };
  },
  props: {
    datas: Object,
  },

  components: {},

  methods: {
    inputChange() {
      this.toUpdate = true;
    },
    cancel() {
      this.toUpdate = false;
      location.reload();
    },
    save() {
      this.toUpdate = false;
      var id = this.datas._id;
      axios
        .put(this.$root.API_URL + "/species/" + this.datas._id, {
          datas: this.datas,
        })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    var toto = this.datas;
  },
  //
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->



<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: white;
}
vue-numeric-input {
  max-width:100px;
}
</style>
