import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueSimpleAlert from "vue-simple-alert";
Vue.config.productionTip = false
var service = {
}
var transaction = {
}
var API_URL = process.env.VUE_APP_API_URL;
import VueNumericInput from 'vue-numeric-input';
Vue.use(VueNumericInput);
Vue.use(VueSimpleAlert);
new Vue({
  router,
  vuetify,
  render: h => h(App),
  data() {
    return{
      transaction:transaction,
      service:service,
      API_URL
    }
  }
}).$mount('#app')

